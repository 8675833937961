/// <reference path="../../../typings/jquery/jquery.d.ts"/>

class Session {
    private $loginForm;
    private lv: LifeVine;

    constructor(lv: LifeVine) {
        this.$loginForm = jQuery('#form-login');
        this.$loginForm.bind('submit', event => this.login(event));
        this.lv = lv;
    }

    login(event) {
        event.preventDefault();
        event.stopPropagation();
        this.$loginForm = $(event.target);
        let data = {
            username: this.$loginForm.find('#email').val(),
            password: this.$loginForm.find('#password').val()
        };

        this.lv.session().login(data.username, data.password)
            .fail((xhr) => {
                Materialize.toast(xhr.responseJSON.error, 2000);
            });
    }
}

window.app.page('page-login', () => {
    return params => {
        if (!window.controllers.session) {
            window.controllers.session = new Session(window.lifeVine);
        }
    };
});